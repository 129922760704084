import "./App.css";
import { motion } from "framer-motion";

function App()
{
  return(
    <div className = "container">
      <div className = "hider">
        <motion.img src = "/Resources/Logo.png" alt = "Err" animate = {{ x : ['-50%', '-50%', null, '-100%'], y : ['0%', '0%', '0%', '0%'], opacity : [0, 0, 1, 1] }} transition = {{ duration : 3, times : [0, 0.5, 0.75, 1] }} />
        <div className = "block">

        </div>
        <motion.div className = "text" animate = {{ x : ['0%', '0%', '125%'], y : ['-50%', '-50%', '-50%'] }} transition = {{ duration : 3, times : [0, 0.75, 1] }}>
          <h1>Ragan</h1>
          <h2>Industries</h2>
          <p>&#10639;Innovation Simplified Rapid&#10638;</p>
        </motion.div>
      </div>
      <motion.h1 animate = {{ opacity : [0, 0, 1] }} transition = {{ duration : 5, times : [0, 0.9, 1] }}>Under Construction</motion.h1>
    </div>
  );
}

export default App;